import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Spin, Tag, Timeline, Tooltip, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaList } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { TreeSelectLocationUser } from "../../components/TreeSelectLocation";
import Api, { Api_v2, exceptionNotificationAPI } from "../../services/api";
import { TASK_STATUS } from '../FormBuilder/const';

const { Title, Text } = Typography;

const CORRELATION_STATUS = {
    0: "Idle",
    1: "Executing",
    2: "Finished",
    3: "Faulted",
    4: "Aborted",
};

const WorkflowTab = () => {
    const { t } = useTranslation();

    const [workflows, setWorkflows] = useState(null);
    const [definitionId, setDefinitionId] = useState(null);
    const [correlations, setCorrelations] = useState(null);
    const [correlationId, setCorrelationId] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchWorkflows();
    }, []);

    // Fetch workflows
    const fetchWorkflows = async () => {
        setLoading(true);
        Api.post("Task/getLatestPublishedWorkflows")
            .then((response) => {
                setWorkflows(response.data.response);
                resetState(); // Reset navigation
            })
            .catch((error) => {
                exceptionNotificationAPI(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [locationId, setLocationId] = useState(null);

    useEffect(() => {
        fetchCorrelations(definitionId, locationId);
    }, [locationId])

    const handleDownload = async (correlationId) => {
        try {
            setLoading(true);
            const response = await Api_v2.Workflow.PdfReport(correlationId);

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

            const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
            const filename = `Workflow_${correlationId}_Report_${date}.pdf`;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Erro ao baixar o PDF', error);
        }
    };

    // Fetch correlations
    const fetchCorrelations = async (definitionId, locationId = null) => {
        setLoading(true);
        Api.post(`Task/getLatestPublishedWorkflowsInstances/${definitionId}` + (locationId ? "/" + locationId : ""))
            .then((response) => {
                setCorrelations(response.data.response);
                setDefinitionId(definitionId);
                setTasks(null); // Reset tasks
            })
            .catch((error) => {
                exceptionNotificationAPI(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Fetch tasks
    const fetchTasks = async (correlationId) => {
        setLoading(true);
        setCorrelationId(null);
        Api.post(`Task/getTasksByCorrelationId/${correlationId}`)
            .then((response) => {
                setCorrelationId(correlationId);
                setTasks(response.data.response);
            })
            .catch((error) => {
                exceptionNotificationAPI(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Reset navigation state
    const resetState = () => {
        setDefinitionId(null);
        setCorrelations(null);
        setTasks(null);
    };

    // Navigation back
    const goBack = () => {
        if (tasks) {
            setTasks(null);
        } else if (correlations) {
            setCorrelations(null);
            setDefinitionId(null);
        }
    };

    // Get correlation status tag
    const getCorrelationStatusTag = (status) => {
        let color = "default";
        switch (status) {
            case 0:
                color = "rgb(199 200 202)";
                break;
            case 1:
                color = "#4085c6";
                break;
            case 2:
                color = "rgb(103 209 129)";
                break;
            case 3:
                color = '#ff4d4f';
                break;
            case 4:
                color = '#ff4d4f';
                break;
            default:
                color = "default";
        }
        return <Tag color={color}>{t(CORRELATION_STATUS[status])}</Tag>;
    };

    // Get task status tag
    const getTaskStatusTag = (status) => {
        var orStatus = status;
        status = TASK_STATUS[status];
        let color = "default";
        if (status === TASK_STATUS.Pending) {
            color = 'rgb(199 200 202)';
        } else if (status === TASK_STATUS.Open) {
            color = '#4085c6';
        } else if (status === TASK_STATUS.Done) {
            color = 'rgb(103 209 129)';
        } else if (status === TASK_STATUS.Late) {
            color = '#ff4d4f';
        }
        return <Tag color={color}>{t(orStatus)}</Tag>;
    };

    const groupTasksByParent = (tasks) => {
        return tasks.reduce((acc, task) => {
            if (task.taskTypeId === 'df3cb0ad-9e0a-44e1-898c-3e3a5df1483e') {
                // É uma tarefa de aprovação, adicione-a sob o taskId da tarefa pai
                if (!acc[task.taskId]) {
                    acc[task.taskId] = { parent: null, approvals: [] };
                }
                acc[task.taskId].approvals.push(task);
            } else {
                // É uma tarefa pai, adicione-a como pai
                acc[task.id] = acc[task.id] || { parent: null, approvals: [] };
                acc[task.id].parent = task;
            }
            return acc;
        }, {});
    };

    return (
        <Spin spinning={loading} size="large">
            <div>
                <Title level={3}>{t("Workflow Management")}</Title>
                {tasks && (
                    <>
                        <Row gutter={[8, 8]}>
                            <Col span={3}>
                                <Button
                                    onClick={goBack}
                                    icon={<ArrowLeftOutlined />}
                                    style={{ marginBottom: "20px" }}
                                >
                                    {t("Back to Correlations")}
                                </Button>
                            </Col>
                            <Col span={4}>
                                <Button
                                    type="primary"
                                    onClick={() => handleDownload(correlationId)}
                                    icon={<DownloadOutlined />}
                                    style={{ marginBottom: "20px" }}
                                >
                                    {t('Report')}
                                </Button>
                            </Col>
                        </Row>
                        <Timeline>
                            {Object.values(groupTasksByParent(tasks)).map((group, index) => (
                                <Timeline.Item
                                    key={index}
                                    color="blue"
                                    dot={<FaList />}
                                    style={{ paddingBottom: 5 }}
                                >
                                    {group.parent && (
                                        <Card className="new-card-history card-p-10" style={{ cursor: "pointer" }}>
                                            {group.parent.taskTypeId === 'df3cb0ad-9e0a-44e1-898c-3e3a5df1483e' ? (
                                                <div>
                                                    <Row>
                                                        <Col span={3}>
                                                            <Text>{t("TaskChangeDate")}: </Text><br />
                                                            <strong style={{ color: "#000" }}>
                                                                {moment(group.parent.insertDate)?.format("YYYY-MMM-DD HH:mm")}
                                                            </strong>
                                                        </Col>
                                                        <Col span={6}></Col>
                                                        <Col span={6}></Col>
                                                        <Col span={4}>
                                                            <Text>{t("TaskApproved")}: </Text><br />
                                                            <strong style={{ color: "#000" }}>{group.parent.userName}</strong>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Text>{t("Status")}: </Text><br />
                                                            {getTaskStatusTag(group.parent.status)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                <Link
                                                    target="_blank"
                                                    to={`/formbuilder/view-answer/${group.parent.id}`}
                                                >
                                                    <Tooltip title={t("Click to view the task")}>
                                                        <Row>
                                                            <Col span={3}>
                                                                <Text>{t("TaskChangeDate")}: </Text><br />
                                                                <strong style={{ color: "#000" }}>
                                                                    {moment(group.parent.insertDate)?.format("YYYY-MMM-DD HH:mm")}
                                                                </strong>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Text>{t("Form Name")}: </Text><br />
                                                                <strong style={{ color: "#000" }}>{group.parent.name}</strong>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Text>{t("Description")}: </Text><br />
                                                                <strong style={{ color: "#000" }}>{group.parent.description}</strong>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Text>{t("AssignedTo")}: </Text><br />
                                                                <strong style={{ color: "#000" }}>{group.parent.userName}</strong>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Text>{t("Status")}: </Text><br />
                                                                {getTaskStatusTag(group.parent.status)}
                                                            </Col>
                                                        </Row>
                                                    </Tooltip>
                                                </Link>
                                            )}
                                        </Card>
                                    )}
                                    {group.approvals.length > 0 && (
                                        <Timeline>
                                            {group.approvals.map((approval, approvalIndex) => (
                                                <Timeline.Item
                                                    key={approvalIndex}
                                                    color={approval.status === "Done" ? "green" : "blue"}
                                                    dot={<FaList />}
                                                    style={{ paddingBottom: 5 }}
                                                >
                                                    <Card className="new-card-history card-p-10" style={{ cursor: "pointer" }}>
                                                        {approval.taskTypeId === 'df3cb0ad-9e0a-44e1-898c-3e3a5df1483e' ? (
                                                            <div>
                                                                <Row>
                                                                    <Col span={3}>
                                                                        <Text>{t("TaskChangeDate")}: </Text><br />
                                                                        <strong style={{ color: "#000" }}>
                                                                            {moment(approval.insertDate)?.format("YYYY-MMM-DD HH:mm")}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Text>{t("TaskApproved")}: </Text><br />
                                                                        <strong style={{ color: "#000" }}>{approval.userName}</strong>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Text>{t("Status")}: </Text><br />
                                                                        {getTaskStatusTag(approval.status)}
                                                                    </Col>
                                                                    <Col span={6}></Col>
                                                                    <Col span={6}></Col>
                                                                </Row>
                                                            </div>
                                                        ) : (
                                                            <Link
                                                                target="_blank"
                                                                to={`/formbuilder/view-answer/${approval.id}`}
                                                            >
                                                                <Tooltip title={t("Click to view the approval task")}>
                                                                    <Row>
                                                                        <Col span={3}>
                                                                            <Text>{t("TaskChangeDate")}: </Text><br />
                                                                            <strong style={{ color: "#000" }}>
                                                                                {moment(approval.insertDate)?.format("YYYY-MMM-DD HH:mm")}
                                                                            </strong>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Text>{t("Form Name")}: </Text><br />
                                                                            <strong style={{ color: "#000" }}>{approval.name}</strong>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Text>{t("Description")}: </Text><br />
                                                                            <strong style={{ color: "#000" }}>{approval.description}</strong>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Text>{t("AssignedTo")}: </Text><br />
                                                                            <strong style={{ color: "#000" }}>{approval.userName}</strong>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Text>{t("Status")}: </Text><br />
                                                                            {getTaskStatusTag(approval.status)}
                                                                        </Col>
                                                                    </Row>
                                                                </Tooltip>
                                                            </Link>
                                                        )}
                                                    </Card>
                                                </Timeline.Item>
                                            ))}
                                        </Timeline>
                                    )}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </>
                )}

                {correlations && !tasks && (
                    <>
                        <Row gutter={[8, 8]}>
                            <Col span={3}>
                                <Button
                                    onClick={goBack}
                                    icon={<ArrowLeftOutlined />}
                                    style={{ marginBottom: "20px" }}
                                >
                                    {t("Back to Workflows")}
                                </Button>
                            </Col>
                            <Col span={4}>
                                <TreeSelectLocationUser
                                    placeholder={t('Location')}
                                    includeDirectParents={false}
                                    className="space-search pl-15"
                                    value={locationId}
                                    onChange={(e) => setLocationId(e)}
                                ></TreeSelectLocationUser>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            {correlations.map((correlation, index) => (
                                <Col span={24} key={index}>
                                    <Card className="new-card-history card-p-10" style={{ cursor: "pointer" }} onClick={() => fetchTasks(correlation.correlationId)}>
                                        <Row>
                                            <Col span={2}>
                                                <Text>{t("Correlation ID")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{correlation.correlationId}</strong>
                                            </Col>
                                            <Col span={2}>
                                                <Text>{t("Status")}: </Text><br />
                                                {getCorrelationStatusTag(correlation.status)}
                                            </Col>
                                            <Col span={5}>
                                                <Text>{t("Last Task Description")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{correlation.lastRunningTaskName}</strong>
                                            </Col>
                                            <Col span={5}>
                                                <Text>{t("Task Location Name")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{correlation.lastRunningLocationName}</strong>
                                            </Col>
                                            <Col span={6}>
                                                <Text>{t("Last Form Name")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{correlation.lastRunningFormName}</strong>
                                            </Col>
                                            <Col span={4}>
                                                <Text>{t("Last Task Assigned To")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{correlation.lastRunningTaskUserName}</strong>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </>
                )}

                {workflows && !correlations && !tasks && (
                    <>
                        <Row gutter={[8, 8]}>
                            {workflows.map((workflow, index) => (
                                <Col span={24} key={index}>
                                    <Card className="new-card-history card-p-10" style={{ cursor: "pointer" }} onClick={() => fetchCorrelations(workflow.definitionId)}>
                                        <Row>
                                            <Col span={12}>
                                                <Text>{t("Workflow Name")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{workflow.name}</strong>
                                            </Col>
                                            <Col span={12}>
                                                <Text>{t("Version")}: </Text><br />
                                                <strong style={{ color: '#000' }}>{workflow.version}</strong>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </>
                )}
            </div>
        </Spin>
    );
};

export default WorkflowTab;
